<template>
<!-- 合同文件审核数据页 -->
  <div>
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px'
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData">
    </base-form>
    <div class="line"></div>
    <!-- 数据表格 -->
    <base-table
    class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        stripe: true,
      }"
      :key="activeNames"
      ref="tableData"
      :dataSource.sync="tableData"
      :api="api"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :getApi="activeNames == 1?'getPlantodo': 'getPlandoto'"
      :webPage="false">
      <template slot="code" slot-scope="scope">
        {{scope.$index+1}}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
         <icon-button  @click="audit(scope.row,'E')" v-if="activeNames ==='1'" content="审核" icon="iconfont iconshenhe1"/>
         <icon-button  @click="info(scope.row)" v-if="activeNames !=='1'" content="查看" icon="iconfont iconchakan"/>
      </template>
    </base-table>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { factorApi, progressApi } from '@/api/businessApi'
import Storage from '@/utils/storage'
export default {
  components: { baseForm, BaseTable, IconButton },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        financingCode: '', // 业务编号
        dateLoan: '' // 供应商
      },
      tableData: [],
      loadCount: 0
    }
  },
  props: {
    activeNames: String,
    gysData: Array
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return factorApi
    }
  },
  mounted () {
    this.clearParams()
    this.handleFilter()
  },
  methods: {
    info (row) {
      Storage.setSession('examine', row)
      Storage.setSession('activeNames', this.activeNames)
      this.$router.push({ path: '/business/contractFileReview/contractFileReviewFactor', query: { type: 'V' } })
    },
    handleFilter () {
      this.loadCount++
    },
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1,
        processStatus: 'CONTRACT'
      }
      if (this.activeNames === '2') {
        this.queryParas.reviewStatus = '10'
      } else if (this.activeNames === '3') {
        this.queryParas.reviewStatus = '20'
      }
    },
    // 审核
    audit (row, type) {
      row.businessId = row.finBusuinessInfoVO.keyId
      Storage.setSession('examine', row)
      Storage.setSession('activeNames', this.activeNames)

      const params = {
        businessId: row.businessId,
        instanceId: row.instanceId,
        taskId: row.taskId
      }

      progressApi.updatestatus(params).then(res => {
        if (res.success) {
          this.$router.push({
            path: '/business/contractFileReview/contractFileReviewFactor',
            query: { type: type }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.line{
  height: 10px;
  background: #F6F6F4;
}
</style>
